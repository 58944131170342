<template>
  <div class="product-slider">
    <div class="product-features">
      <template v-for="(item, index) in getSticker()" :key="index">
        <div :class="item.class" class="product-feature">{{ item.title }}</div>
      </template>
    </div>
    <div class="product-slider__favorite">
      <LikeButton
        :classItem="'like-btn--big'"
        :productId="mainProductId"
        :isFavourite="product.isFavoriteUser"
      />
    </div>
    <Slider v-if="product.pictureSlider.length > 0" :sliders="product.pictureSlider" :swiperOptions="swiperOptions"/>
    <LazyPicture
      v-else
      src-placeholder="/img/no_img_src.png"
      :src="product.picture"
      :alt="product.name"
      class="blur-up"
    />
  </div>
  <div class="container">
    <div class="product-information">
      <div class="product-information__header">
        <div class="product-information__text title--second">
          {{ product.name }}
        </div>
        <div class="product-information__rating" v-if="product.vote_sum">
          <div class="product-information__rating-icon">
            <svg class="icon icon-star ">
              <use xlink:href="#star"></use>
            </svg>
          </div>
          <div class="product-information__rating-text">
            {{ product.vote_sum }}
          </div>
        </div>
      </div>
      <div class="product-information__code">Арт: {{ product.article }}</div>
      <div :class="{ 'product-information__price--sale': product.isDiscount }" class="product-information__price ">
        <div class="product-information__price-num">
          {{ product.priceDiscount + " " + product.currency }}<span>/ шт</span>
        </div>
        <div class="product-information__price-old" v-if="product.isDiscount">
          {{ product.price + " " + product.currency }}
        </div>
      </div>
      <div class="product-information__available" v-if="product.quantity > 0">
        <div class="product-information__available-icon">
          <svg class="icon icon-square ">
            <use xlink:href="#square"></use>
          </svg>
        </div>
        <div class="product-information__available-text">
          В наличии<span class="product-information__available-span">({{ product.quantity }} шт)</span>
        </div>
      </div>
      <div class="product-information__available" v-else>
        <div class="product-information__available-icon product-information__available-icon--not">
          <svg class="icon icon-cross ">
            <use xlink:href="#cross"></use>
          </svg>
        </div>
        <div class="product-information__available-text">Нет в наличии</div>
      </div>
      <div v-if="product.fit_until" class="product-information__fit-until">Годен до: {{ product.fit_until }}</div>
      <div class="product-information__buy-alt" v-if="product.is_partner_product">
        <a class="btn btn-standalone" :href="product.partner_product_url" v-if="product.partner_product_url">
          Купить на сайте продавца
          <img class="icon" src="/img/link.svg" alt=""/>
        </a>

        <div class="buy-alt__available-text" v-if="product.partner_telegram">Заказать товар в:</div>

        <div class="buy-alt__btn-group">
          <a class="btn btn--outline telegram" :href="product.partner_telegram" v-if="product.partner_telegram">
            <img class="icon" src="/img/telegram.svg" alt=""/>
            Telegram
          </a>
          <a class="btn btn--outline whatsapp" :href="whatsappUrl" v-if="product.partner_whatsapp">
            <img class="icon" src="/img/whatsapp.svg" alt=""/>
            WhatsApp
          </a>
        </div>
      </div>
    </div>
    <div class="product-offers">
      <div class="product-description__title title title--second">Цвет</div>
      <button
        v-for="(offer, index) in product.offers"
        :key="offer.id"
        @click.prevent="changeOffer(index)"
        :class="['product-offers__button', index === this.currentOffer ? 'active' : '']"
      >
        {{ offer.color }}
      </button>
    </div>
    <div v-if="product.description" class="product-description">
      <div class="product-description__title title title--second">Описание</div>
      <div v-html="product.description" class="product-description__text"></div>
    </div>
    <div class="product-reviews">
      <div class="product-reviews__title title title--second">Отзывы</div>
      <div class="product-reviews__wrapper" >
        <template v-if="product.reviews.length > 0">
          <Review v-for="review in product.reviews" :key="review.id" :review="review"/>
        </template>
        <div class="product-reviews__no-reviews" v-else>
          На данный товар еще нет отзывов, будьте первым, кто оставит его
        </div>
      </div>
      <div class="product-reviews__button">
        <button @click.prevent="openModalReview()" class="btn btn--review">
          <svg class="icon icon-rev">
            <use xlink:href="#rev"></use>
          </svg>
          Оставить отзыв
        </button>
      </div>
    </div>
  </div>
  <div class="cart-buy cart-buy--product" v-if="product.quantity > 0 && !product.is_partner_product">
    <div class="container">
      <div class="cart-buy__wrapper">
        <div class="cart-buy__sum">
          <button
            @click="counter--"
            :disabled="counter <= 1"
            class="btn btn--counter">
            -
          </button>
          <span class="cart-buy__count">{{ counter }}</span>
          <button
            @click="counter++"
            :disabled="product.quantity - productCountInBasket <= counter"
            class="btn btn--counter">
            +
          </button>
        </div>
        <div v-if="productCountInBasket" class="cart-buy--in-basket">{{ productCountInBasket }}</div>
        <div class="cart-buy__btn">
          <template v-if="!isButtonBasket">
            <button
              @click.prevent="addProductToCart()"
              :disabled="product.quantity - productCountInBasket < counter"
              class="btn">
              Добавить в корзину
            </button>
          </template>
          <template v-else>
            <router-link to="/checkout" class="btn btn--in-basket">В корзине</router-link>
          </template>
        </div>
      </div>
    </div>
  </div>

  <ModalReview />
</template>

<script>
import Slider from "@/components/sliders/product";
import LikeButton from "@/components/button/like";
import Review from "@/components/cards/review";
import ModalReview from "@/components/modals/modal-review";

import store from "@/store/index.js";

export default {
  components: {
    LikeButton,
    Slider,
    Review,
    ModalReview,
  },

  beforeRouteEnter(to, from, next) {
    const data = {
      SECTION_CODE: to.params.subsection,
      ELEMENT_CODE: to.params.detail,
    };
    store.dispatch("CATALOG_FETCH_PRODUCT", data).then(() => {
      next();
    });
  },

  data() {
    return {
      swiperOptions: {
        direction: "horizontal",
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
      },
      product: {},
      counter: 1,
      currentOffer: 0,
      mainProductId: null,
      isButtonBasket: false,
      whatsappUrl: '',
    };
  },

  created() {
    this.product = this.$store.state.catalog.product;
    this.mainProductId = this.$store.state.catalog.product.id;

    if (this.product.offers.length > 0) {
      let offer = this.product.offers[0];

      this.product = {...this.product, ...offer};
    }

    this.whatsappUrl = this.product.partner_whatsapp + '?text=Здравствуйте, я хочу купить товар: ' + window.location.href
  },

  computed: {
    productCountInBasket() {
      if (Object.keys(this.$store.state.basket).length > 0)
        return this.getCountInBasketUser();

      if (this.$store.state.basketGuest)
        return this.getCountInBasketGuestUser();

      return 0;
    },
  },

  methods: {
    openModalReview() {
      this.$store.commit("CATALOG_SET_STATE", {
        key: "isShowModalReview",
        data: true,
      });
    },

    addProductToCart() {
      const data = { PRODUCT_ID: this.product.id, QUANTITY: this.counter };
      this.$store
        .dispatch("CHECKOUT_SEND_PRODUCT_TO_CART", data)
        .then(() => {
          this.isButtonBasket = true;
          this.counter = 1;
        })
        .catch((error) => {
          if (error.response !== undefined) {
            this.errors = error.response.data.errors;

            this.$store.commit("SET_MESSAGE_MODAL_DATA", error.response.data);
          }
        });
    },

    changeOffer(key) {
      let offer = this.product.offers[key];

      this.product = {...this.product, ...offer};
      this.currentOffer = key;
    },

    getSticker() {
      let sticker = [];

      if (this.product.stocks)
        sticker.push({
          'title': 'Акция',
          'class': 'product-feature--orange'
        });

      if (this.product.novelties)
        sticker.push({
          'title': 'Новинка',
          'class': 'product-feature--green'
        });

      if (this.product.sovetuem)
        sticker.push({
          'title': 'Советуем',
          'class': 'product-feature--purple'
        });

      if (this.product.hit)
        sticker.push({
          'title': 'Хит',
          'class': 'product-feature--blue'
        });

      if (this.product.preimushestva)
        sticker.push({
          'title': 'Преимущества',
          'class': 'product-feature--yellow'
        });

      if (this.product.is_partner_product)
        sticker.push({
          'title': 'Товар партнера',
          'class': 'product-feature--pink'
        });

      if (this.product.is_social_product)
        sticker.push({
          'title': 'Социальный товар',
          'class': 'product-feature--red'
        });

      return sticker;
    },

    /**
     * Количество товара в корзине авторизованного пользователя
     */
    getCountInBasketUser() {
      let products = this.$store.state.basket.items;
      let product = Object.values(products).find((item) => parseInt(item.id) === this.product.id);

      if (product)
        return parseInt(product.quantity);
      else
        return 0;
    },

    /**
     * Количество товара в корзине неавторизованного пользователя
     */
    getCountInBasketGuestUser() {
      let products = this.$store.state.basketGuest;
      let product = Object.values(products).find(item => item.productId === this.product.id);

      if (product)
        return parseInt(product.quantity);
      else
        return 0;
    },
  },
};
</script>
