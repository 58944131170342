<template>
  <button
    class="like-btn"
    :class="classItem + ' ' + isClassActive"
    @click.prevent="addFavorites()"
  >
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="like-btn__icon like-btn__icon--outline"
    >
      <path
        d="M2.34079 2.43007C5.58656 -0.626932 8.86582 1.98506 10.956 4.17141C13.1098 1.99477 16.7163 -0.713989 19.5714 2.43007C23.8589 7.38317 17.766 12.0102 10.956 17.6667C4.09384 12.0074 -1.71642 6.25132 2.34079 2.43007Z"
        stroke="#333333"
        stroke-width="1.5"
      ></path>
    </svg>
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="like-btn__icon like-btn__icon--filled"
    >
      <path
        d="M19.8766 1.67591C19.0689 0.78649 18.1702 0.273601 17.2203 0.0841855C16.2787 -0.103543 15.3505 0.0389423 14.4941 0.350783C13.0681 0.870008 11.7511 1.89207 10.7159 2.86443C9.72635 1.8981 8.48431 0.890706 7.08952 0.380217C6.2463 0.0716019 5.32811 -0.0627704 4.37273 0.118169C3.41499 0.299556 2.47568 0.78733 1.57658 1.63414C0.407882 2.73487 -0.0858228 4.02698 0.0121115 5.42933C0.106865 6.78613 0.749553 8.17186 1.68551 9.5339C3.55582 12.2557 6.8025 15.1696 10.2288 17.9953L10.7077 18.3903L11.1852 17.9937C11.506 17.7272 11.8263 17.4622 12.1451 17.1985C15.1696 14.6962 18.0528 12.3109 19.778 9.98096C20.7409 8.68064 21.4032 7.32237 21.4903 5.90085C21.5791 4.4511 21.0635 3.04675 19.8884 1.68925L19.8826 1.68251L19.8766 1.67591Z"
        fill="#F2456E"
      ></path>
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    classItem: {
      type: String,
      required: false,
      default: "",
    },
    productId: {
      type: Number,
      required: true,
    },
    isFavourite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      favorite: this.isFavourite,
    };
  },

  computed: {
    isClassActive() {
      return this.favorite ? "like-btn--active" : "";
    },
  },

  methods: {
    addFavorites() {
      this.$store
        .dispatch("FAVORITES_ADD_PRODUCT", this.productId)
        .then(() => {
          this.favorite = !this.favorite;
        });
    },
  },
};
</script>
