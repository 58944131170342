<template>
  <label :for="id" class="label">{{ label }}</label>
  <input
    @input="onInput($event)"
    :id="id"
    :type="type"
    :value="modelValue"
    :disabled="disabled"
    :required="required"
    v-maska="pattern"
    class="input"
    autocomplete="off"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    id: {
      type: String,
    },
    type: {
      default: 'text',
    },
    modelValue: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
    },
  },

  emits: ['update:modelValue', 'change'],

  methods: {
    onInput(event) {
      this.$emit('update:modelValue', event.target.value)
      this.$emit('change')
    }
  }
};
</script>
