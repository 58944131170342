<template>
  <div class="swiper-container" :class="classItem">
    <swiper
      class="swiper-wrapper"
      ref="mySwiper"
      :auto-update="true"
      :auto-destroy="true"
      :options="swiperOptions"
      :pagination="{ clickable: true }"
    >
      <swiper-slide
        class="swiper-slide"
        v-for="(slider, index) in sliders"
        :key="index"
      >
        <LazyPicture
          src-placeholder="/img/no_img_src.png"
          :src="slider"
          class="blur-up"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import SwiperCore, {Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";

SwiperCore.use([Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    sliders: {
      type: Array,
      required: true,
    },
    swiperOptions: {
      type: Object,
      required: true,
    },
    classItem: {
      type: String,
      required: false,
    },
  },
};
</script>
