<template>
  <div class="home-review">
    <div class="review">
      <div class="review__head">
        <div class="review-person">
          <div class="review-person__info">
            <div class="review-person__info-name">
              {{ review.user ? review.user : "Анонимный пользователь" }}
            </div>
            <div class="review-person__info-date" v-if="review.dateCreate">
              {{ review.dateCreate }}
            </div>
          </div>
        </div>
        <div class="review__rating">
          <div
            class="review__rating-background"
            v-if="review.ratingAverage"
            :style="'width:' + (review.ratingAverage / 5) * 100 + '%'"
          >
            <img src="/img/rating.svg" />
          </div>
        </div>
      </div>
      <div class="review__body" v-if="review.text">
        {{ review.text }}
      </div>
      <button class="review__body-more" @click.prevent="isShowModal = true">
        Читать дальше
      </button>
    </div>
    <div v-if="isShowModal" class="modal-mask">
      <div class="modal-wrap">
        <div class="modal modal--info animated">
          <button type="button" class="modal__close" @click="closeModal()">
            <svg class="icon icon-cross">
              <use xlink:href="#cross"></use>
            </svg>
          </button>
          <div class="review review--modal">
            <div class="review__head">
              <div class="review-person">
                <div class="review-person__info">
                  <div class="review-person__info-name">
                    {{ review.user ? review.user : "Анонимный пользователь" }}
                  </div>
                  <div class="review-person__info-date" v-if="review.dateCreate">
                    {{ review.dateCreate }}
                  </div>
                </div>
              </div>
              <div class="review__rating">
                <div
                  class="review__rating-background"
                  v-if="review.ratingAverage"
                  :style="'width:' + (review.ratingAverage / 5) * 100 + '%'"
                >
                  <img src="/img/rating.svg" />
                </div>
              </div>
            </div>
            <div class="review__body" v-if="review.text">
              {{ review.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    review: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      isShowModal: false,
    };
  },

  methods: {
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>
