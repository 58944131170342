<template>
  <div v-if="this.isModal" class="modal-mask">
    <div class="modal-wrap">
      <div class="modal modal--info animated">
        <button type="button" class="modal__close" @click="closeModal()">
          <svg class="icon icon-cross">
            <use xlink:href="#cross"></use>
          </svg>
        </button>
        <div class="review review--modal">
          <form @submit.prevent="sendRequest()" ref="form">
            <FormText
              :label="'Ваше имя'"
              :required="true"
              v-model="userName"
            />
            <div class="resume-form__item">
              <div class="resume-form__label">Комментарий *</div>
              <div class="resume-form__input">
                <FormArea
                  :label="'Комментарий'"
                  :required="true"
                  v-model="message"
                />
              </div>
            </div>
            <button class="btn">Оставить отзыв</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormText from "@/components/forms/form-text";
import FormArea from "@/components/forms/textarea";

export default {
  name: "modal-review",

  components: {
    FormText,
    FormArea
  },

  data() {
    return {
      userName: '',
      message: ''
    };
  },

  computed: {
    isModal() {
      return this.$store.state.catalog.isShowModalReview;
    },
  },

  methods: {
    sendRequest() {
      this.$store
        .dispatch("REVIEW_SEND_FORM_ABOUT_PRODUCT", {
          TOPIC_TITLE: this.$store.state.catalog.product.name,
          PRODUCT_ID: this.$store.state.catalog.product.id,
          USER_NAME: this.userName,
          MESSAGE: this.message,
        })
        .then((response) => {
          this.$store.commit("SET_MESSAGE_MODAL_DATA", response.data);
        })
        .catch((error) => {
          this.$store.commit("SET_MESSAGE_MODAL_DATA", error.response.data);
        })
        .then(() => {
          this.closeModal();
        });
    },

    closeModal() {
      this.$store.commit("CATALOG_SET_STATE", {
        key: "isShowModalReview",
        data: false,
      });
    }
  },
}
</script>
