<template>
  <textarea
    name="name"
    ref="input"
    :placeholder="label"
    class="input textarea"
    v-model="value"
    autocomplete="off"
    :disabled="disabled"
    :required="required"
    @focus="isFocus = true"
    @blur="onBlur"
  >
  </textarea>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
    },
    currentValue: {
      default: "",
    },
    type: {
      default: "text",
    },
    error: {
      default: "",
    },
    required: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },

  data() {
    return {
      value: this.currentValue,
      isFocus: false,
    };
  },

  methods: {
    onBlur() {
      this.isFocus = false;
      this.$emit("update:modelValue", this.value);
    },
    reset() {
      this.value = "";
      this.isFocus = false;
    },
  },
};
</script>
